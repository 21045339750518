import React, { Fragment, Component } from 'react';
import { Disclosure, Transition, Dialog} from '@headlessui/react'
import * as heroIcons from 'react-icons/hi';
import {instance, checkToken} from '../../../globalData'
import DownloadLink from "react-download-link";
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';

const defaultForm = {
  name: 0,
  day:0,
  month: 0,
  year: 0,
  customName: "",
  filesPath: [],
  filesId: 0,
}

var name = [
  {label: "KBIS", value:1},
  {label: "URSSAF", value:2},
  {label: "RC", value:3},
  {label: "RD", value:4},
  {label: "Congés payés", value:5},
  {label: "PRO BTP", value:6},
  {label: "Impots Attestation", value:7},
  {label: "liste de salariés", value:8},
  {label: "autre", value:9},
]

var day = []

var month = [
    {label: "Janvier", value:"01"},
    {label: "Février", value:"02"},
    {label: "Mars", value:"03"},
    {label: "Avril", value:"04"},
    {label: "Mai", value:"05"},
    {label: "Juin", value:"06"},
    {label: "Juillet", value:"07"},
    {label: "Août", value:"08"},
    {label: "Septembre", value:"09"},
    {label: "Octobre", value:"10"},
    {label: "Novembre", value:"11"},
    {label: "Decembre", value:"12"},
]

var year = []

class ShowCompany extends Component {

  constructor(props){
    super(props)
    this.state = {
      company: [],
      listLots:[],
      formOpen: false,
      showOpen:false,
      fileShow:0,
      form:defaultForm,
      files: []
    }
    this.ref = React.createRef();
    this.ref2 = React.createRef();
    this.fileReference = React.createRef();
  }

  async deleteDocument(id){
    await checkToken()
    instance.delete(`companydocuments/${this.state.files[id].id}/`)
    .then(()=>{
      this.getFiles()
    })
    this.setState({showOpen: false})
  }

  setShowClose(){
    this.setState({showOpen: false});
  }

  async getFiles(){
    var files = []
    await checkToken()
    instance.get(`companydocuments/?company=${this.props.match.params.companyId}`)
    .then((res)=>{
      const response = res.data.map((e) => {
        if(e.docType !== 9){
          delete name[e.docType - 1]
        }
        files.push(e)
      })
      Promise.all(response).then(() => this.setState({files: files}))
    })
  }

  async setAddFormClose(){
    // this.setState({formOpen: false, form: defaultForm,})
    this.setState({formOpen: false, form: defaultForm})
    if(this.state.form.filesId !== 0){
      await checkToken()
      instance.delete(`companydocuments/${this.state.form.filesId}/`)
    }
  };

  onSelectChange(e, name){
    this.setState(prev => ({
        form:{
            ...prev.form,
            [name]:e.value,
        }
    }))
  }

  initValue(){
    for(var i = 1; i < 32; i++){
        var d={}
        var y={}
        y.value = ("20" + (10 + i))
        y.label = ("20" + (10 + i))
        if(i < 10){
            d.value = "0" + i
        }
        else{
            d.value = "" + i
        }
        d.label = "" + i
        day.push(d)
        year.push(y)
    }
}

onChange = (e) => {
  // this.setState({ form:{[e.target.name]: e.target.value }})
  this.setState(prev => ({
      form:{
          ...prev.form,
          [e.target.name]:e.target.value,
      }
  }))
};

async fileUploadAction(e) {
  let newArray = [...this.state.form.filesPath, e.target.files[0]]
  this.setState(prev => ({
      form:{
          ...prev.form,
          'filesPath':newArray,
      }
  }))
  let data = new FormData();
  data.append('filePath', e.target.files[0]);
  data.append('company', this.props.match.params.companyId)
  const options = {
      onUploadProgress: (progressEvent) => {
          const {loaded, total} = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`) 
      }
  }
  await checkToken()
  instance.post("companydocuments/", data, options).then(
      res => {
          this.setState(prev => ({
              form:{
                  ...prev.form,
                  ['filesId']:res.data.id,
              }
          }))
      }
  )
}

fileClickAction = () =>this.fileReference.current.click();

  AddForm = () => {
    let listFiles = []
    if(this.state.form.filesPath.length !== 0){
        listFiles.push(<p className="text-l underline">Nom du document: </p>)
    }
    this.state.form.filesPath.map((e) => {
        listFiles.push(<p>{e.name}</p>)
    })
    var customName = []
    if(this.state.form.name === 9){
      customName.push(
        <>
          <label>Nom</label>
          <input  name="customName"
                  placeholder="Nom"
                  className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                  onChange={(e)=>this.onChange(e)}
                  value={this.state.form.customName}
          
          />
        </>
      )
    }
    else{
      customName = []
    }
    return(
        <>
        <div className="mt-2 min-h-52">
        <div className="grid grid-cols-2 gap-y-4">
          <label>Type de document</label>
          <div>
              <Select
                  className="text-sm"
                  maxMenuHeight="200px"
                  placeholder="Type de document"
                  name="name"
                  value={this.state.name}
                  onChange={(e)=>this.onSelectChange(e, "name")}
                  options={name}
              /></div>
          
          {customName}
          <label>Date d'expiration</label>
              <div className="flex justify-between">
              <div><Select
                  className="w-20 text-sm"
                  maxMenuHeight="150px"
                  placeholder="Jour"
                  name="day"
                  value={this.state.day}
                  onChange={(e)=>this.onSelectChange(e, "day")}
                  options={day}
              /></div>
              <div>
              <Select
                  className="w-24 text-sm"
                  maxMenuHeight="150px"
                  placeholder="Mois"
                  name="month"
                  value={this.state.month}
                  onChange={(e)=>this.onSelectChange(e, "month")}
                  options={month}
              /></div>
              <div><Select
                  className="w-24 text-sm"
                  maxMenuHeight="150px"
                  placeholder="Année"
                  name="year"
                  value={this.state.year}
                  onChange={(e)=>this.onSelectChange(e, "year")}
                  options={year}
              /></div>
            </div>
            <div className="col-span-2">
            {listFiles}
            </div>
            <div className="col-span-2 mx-auto">
        
            
            <div>
                <input
                    hidden
                    name="fileInput"
                    required=""
                    type="file"
                    // className="{className}"
                    ref={this.fileReference}
                    onChange={(e) => this.fileUploadAction(e)}
                /> 
                <div className="flex items-center">
                {this.state.form.filesPath.length === 0 && 
                <button
                    onClick={this.fileClickAction} 
                    className="px-3 py-1 flex m-4 text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <heroIcons.HiUpload className="pr-1 text-xl"/> Envoyerle fichier
                </button>}
                
                </div>
                </div>
                </div>
        </div>
        </div>
        </>
    )
}

  
  getAdress(i){
    return this.state.company.address ? this.state.company.address.split(",")[i] : ""
  }
  
  Listlot = () => {
    if(this.state.listLots.length === 0){
      return(<p className="text-center">Il n'y a aucun projet</p>)
    }
    return(
    <div className="px-2">
    <table className="table-fixed">
      <thead>
      <tr>
      <td className="w-4/5"></td>
      <td className="w-1/5"></td>
      </tr>
      </thead>
      <tbody>
        {this.state.listLots}
      </tbody>
      </table>
    </div>
    )
  }

  async submitForm(){
    
    if( this.state.form.filesId !== 0 && 
        (this.state.form.name !== 0 &&
        (this.state.form.name !== 9 || this.state.form.customName !== "")) &&
        this.state.form.day !== 0 &&
        this.state.form.month !==  0 && 
        this.state.form.year !==  0
        ){
      var date = this.state.form.year + "-" + this.state.form.month + "-" + this.state.form.day
      var fileName = ""
      if(this.state.form.name === 9){
      fileName = this.state.form.customName
      }
      else{
        fileName = name[this.state.form.name - 1].label
      }
      await checkToken()
      instance.patch(`companydocuments/${this.state.form.filesId}/`,{
        "name":fileName,
        "expiration": date,
        "docType": this.state.form.name,
        
        
      }).then((res)=>{
        // refresh is parent function
        this.setState({form:defaultForm, formOpen:false})
        this.initValue()
        this.getFiles()
      })
    }
    else{
      alert('Veuillez renseignez tous les champs')
    }
    }

  async getLots(){
    let content = []
    await checkToken()
    instance.get(`lots/?company=${this.props.match.params.companyId}`).then((res) => {
      res.data.map((lot)=>{
        content.push(
        <tr>
          <td className="">{lot.name}</td>
          <td className="">
          <Link 
              to={`/lots/${lot.project}/${lot.id}/`}>
              <div className="text-xl bg-gray-400 p-0.5 hover:bg-gray-500 rounded-full ml-3"><heroIcons.HiDotsHorizontal/></div>
          </Link>
          </td>
        </tr>
      )})
      this.setState({listLots: content})
    })
        }

        async componentDidMount(){
          const id = this.props.match.params.companyId
          await checkToken()
          instance.get(`company/${id}/`).then((res) =>{
            this.setState({company: res.data})
          })
          this.getLots()
          this.initValue()
          this.getFiles()
        }

        render(){
      return (
        <>


        {/* Afficher document */}
        <Transition show={this.state.showOpen} as={Fragment}>
        <Dialog
            as="div"
            id="modal"
            className="fixed inset-0 z-1 overflow-y-auto"
            initialFocus={this.ref2}
            static
            open={this.state.showOpen}
            onClose={() => null}
        >
            <div className="min-h-screen px-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
    
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
            >
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                    {this.state.files[this.state.fileShow] ? this.state.files[this.state.fileShow].name : "ERROR"}
                </Dialog.Title>
                
                <div className="flex justify-center">
                  <label>Date d'expiration : {this.state.files[this.state.fileShow] ? this.state.files[this.state.fileShow].expiration.split("-")[2] + " " + month[parseInt(this.state.files[this.state.fileShow].expiration.split("-")[1]) - 1].label + " " + this.state.files[this.state.fileShow].expiration.split("-")[0] : "ERROR"}</label>
                </div>
                <div className="mt-4 flex justify-center">
                <button
                    type="button"
                    className="w-30 inline-flex justify-center px-4 py-2 mr-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.deleteDocument(this.state.fileShow)}
                    >
                    Supprimer le document
                    </button>
                {/* <button
                    type="button"
                    className="w-30 inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.setShowClose()}
                    >
                    Télécharger le document
                    </button> */}

                    <DownloadLink
                      label="Télécharger le document"
                      tagName="button"
                      // @ts-ignore
                      style={{}}
                      className="w-30 inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      filename={this.state.files[this.state.fileShow] ? this.state.files[this.state.fileShow].filePath : "error.html"}
                      exportFile={() => "My cached data"}
                  />
                </div>
                <div className="mt-4 flex justify-center">
                    
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={(e)=> this.setShowClose()}
                    >
                    OK
                    </button>
                    
                </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition>

        {/* Ajouter un document */}
                <Transition show={this.state.formOpen} as={Fragment}>
        <Dialog
            as="div"
            id="modal"
            className="fixed inset-0 z-1 overflow-y-auto"
            initialFocus={this.ref}
            static
            open={this.state.formOpen}
            onClose={() => null}
        >
            <div className="min-h-screen px-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
    
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
            >
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                    Ajouter un document
                </Dialog.Title>
                
                <this.AddForm/>
                <div className="mt-4 flex justify-center">
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.setAddFormClose()}
                    >
                    Annuler
                    </button>
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={(e)=> this.submitForm(e)}
                    >
                    Valider
                    </button>
                    
                </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition>
          <div className="grid justify-items-center">
          <h1 className="text-3xl font-bold m-3">Entreprise Quaero</h1>
          <div className=" max-w-5xl p-4  bg-gray-100 rounded-2xl m-2 mx-2">
          <div className="grid md:grid-cols-2 items-center">
            <div className="m-2 p-5 border border-black rounded-lg">
              <p>{this.state.company.name}<br/>{this.getAdress(0)}<br/>{this.getAdress(1)}<br/><br/>N° Siret: {this.state.company.siret}</p>
            </div>
            <div className="m-2">
              
            
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>Liste des lots</span>
                    <heroIcons.HiOutlineChevronDown
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-white`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="">
                    <this.Listlot/>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>



            </div>
            <div className="col-span-2 m-2">
              


              <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span> Documents de l'entreprise</span>
                    <heroIcons.HiOutlineChevronDown
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-white`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="">
                    <div className="grid grid-cols-2">                       
                    {this.state.files.map((file, id)=> {
                      return(
                      <>
                      <div className="place-self-center">
                      {file.name}
                    </div>
                    <div>
                      <button className="px-3 py-1 flex m-4 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full "
                              onClick={()=>this.setState({showOpen: true, fileShow: id})}>
                        <heroIcons.HiOutlineArrowCircleRight className="pr-1 text-xl"/>Afficher</button>
                    </div>
                    </>)
                    })}


                      
                      <div className="col-span-2">
                        <button className="px-3 py-1 flex m-4 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full "
                          ref={this.ref}
                          onClick={() => this.setState({formOpen: true})}
                        >
                          <heroIcons.HiOutlineArrowCircleRight 
                                className="pr-1 text-xl"/>Ajouter un document</button>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>



            </div>
          </div>
        </div>
        </div>
        </>
        );
    }
} export default withRouter(ShowCompany);