import React, { Fragment } from 'react'
import {checkToken, instance} from '../../../globalData'
import {Listbox, Transition, Dialog} from '@headlessui/react'
import * as heroIcons from 'react-icons/hi';
import { withRouter } from 'react-router';


const defaultForm = {
    name: "",
    company: "",
    amount: ""
}

class Imputations extends React.Component {
    
    constructor(props){
    super(props);
    this.state = {
        formOpen: false,
        form: defaultForm,
        lots: [],
        selectedLot: "",
        lotData: {},
        imputations:[],
        imputationsLot:[],
        total:[]
    }
    this.ref = React.createRef();
    }

    async getImputationsLot(){
        var addImputationsLot = []
        var prev = []
        let total = []
        await checkToken()
        instance.get(`detailimputations/${this.props.match.params.projectId}/`).then((res) =>{
            prev = res.data
            let p = res.data.map((imput, i) => {
                total.push(0)
                this.state.lots.map((lot, index) => {
                    if(!imput.imputationsLot.find(e => e.lot === lot.id)){
                        var newLot = {}
                        newLot.id = 0
                        newLot.pourcent = 0
                        newLot.lot = lot.id
                        addImputationsLot.push(newLot)
                    }
                    else{
                        total[i] += imput.imputationsLot.find(e => e.lot === lot.id).pourcent
                    }
                })
                if(addImputationsLot.length !== 0){
                    addImputationsLot.map(e => prev[i].imputationsLot.push(e))
                }
                addImputationsLot = []
        })
        Promise.all(p).then(()=>{
            this.setState({imputations: prev, total: total})
        })
    })
    }


    onChange = (e) => {
    this.setState(prev => ({
        form:{
            ...prev.form,
            [e.target.name]:e.target.value,
        }
        }))
    };

    async submitForm(){
        await checkToken()
        instance.post("imputations/",{
            "name":this.state.form.name,
            "company": this.state.form.company,
            "amount":this.state.form.amount,
            "project": this.props.match.params.projectId
        }).then(()=>{
            this.getImputationsLot()
        }).catch((e)=>{
            alert('Il y a eu une erreur');
        })
        this.setAddFormClose();
    }

    async sendImputation(e){
            if(e.target.id === 0){
                await checkToken()
                instance.post(`imputationslot/`, {
                    "pourcent":e.target.value,
                    "active":true,
                    "lot": this.state.selectedLot.id,
                    "imputation": this.state.imputations[e.target.name].id,
                })
            }
            else{
                await checkToken()
                instance.patch(`imputationslot/${e.target.id}/`, {"pourcent":e.target.value})
            }
        }

    updateImputation(e){
        var prevTotal = this.state.total
        var prev = this.state.imputations
        prevTotal[e.target.name] += (e.target.value - prev[e.target.name].imputationsLot.find(e => e.lot === this.state.selectedLot.id).pourcent)
        prev[e.target.name].imputationsLot.find(e => e.lot === this.state.selectedLot.id).pourcent = e.target.value > 100 ? 100 : e.target.value
        this.setState({imputations: prev, total: prevTotal})
    }

    AddForm = () => {
        return(
            <>
            <div className="mt-2">
            <div className="grid grid-cols-2 gap-y-4">
                    <label>Descriptif</label>
                    <input  name="name"
                            placeholder="description"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                    
                    />
                    <label>Entreprise</label>
                    <input  name="company"
                            placeholder="entreprise"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                    
                    />
                    <label>Montant HT</label>
                    <input  name="amount"
                            placeholder="1000 €"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                    
                    />
                                
                    
            </div>
            </div>
            </>
        )
    }
    
    setAddFormOpen(v) {
        this.setState({formOpen: v});
    }

    //fermer le formulaire d'ajout d'avenant
    setAddFormClose(){
        this.setState({formOpen: false,})
    };

    showtab(){
        let content = []
        if(this.state.imputations.length === 0 ){
            return(<tr><td colSpan="7">Il n'y a aucune imputations</td></tr>)
        }
        else{
        this.state.imputations.map((imput, i) => {
            var lots = imput.imputationsLot.find(e => e.lot === this.state.selectedLot.id)
            if(lots){
                content.push(
                    <tr>
                        <td className="border border-collapse border-gray-900">{imput.name}</td>
                        <td className="border border-collapse border-gray-900">{imput.company}</td>
                        <td className="border border-collapse border-gray-900">{imput.amount}</td>
                        <td className="border border-l-2 border-gray-900">
                            <input  type="text"
                                        name={i}
                                        id={lots.id}
                                        value={lots.pourcent}
                                        onBlur={(event) => this.sendImputation(event)}
                                        onChange={(event) => this.updateImputation(event)}
                                        className = "appearance-none text-center w-10 bg-transparent focus:bg-white focus:outline-none"
                                />
                        </td>
                        <td className="border border-collapse border-gray-900">{lots.pourcent * imput.amount / 100 }</td>
                        <td className="border border-collapse border-gray-900">{lots.pourcent * imput.amount * 0.012}</td>
                        <td className="w-10 border border-l-2 border-collapse border-gray-900">{this.state.total[i]}</td>
                    </tr>)
            }
            })
        }
        return content}
      // fonction recupérant les lots pour les liste deroulante
      async getLots(id){
        //init lot
        await checkToken()
        instance.get('showlots/' + id + "/").then(
          res => {
            this.setState({lots: res.data, selectedLot: res.data[0]})
          }
        );
      }

    async componentDidMount() {
        const projetId = this.props.match.params.projectId;
        await this.getLots(projetId);
        await this.getImputationsLot()
      }
    
      setSelectedLot(event) {
        this.setState({selectedLot: event})
        // this.getImputationsLot()

      }

    render() {
    return(
        <>
        <Transition show={this.state.formOpen} as={Fragment}>
        <Dialog
            as="div"
            id="modal"
            className="fixed inset-0 z-10 overflow-y-auto"
            initialFocus={this.ref}
            static
            open={this.state.formOpen}
            onClose={() => null}
        >
            <div className="min-h-screen px-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
    
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
            >
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                    Ajouter une imputation
                </Dialog.Title>
                
                <this.AddForm/>
                <div className="mt-4 flex justify-center">
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.setAddFormClose()}
                    >
                    Annuler
                    </button>
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={(e)=> this.submitForm(e)}
                    >
                    Valider
                    </button>
                    
                </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition>
    


        <div className="grid justify-items-center">
        <h1 className="m-2 text-xl">Tableau des Imputations</h1>
        <div className=" max-w-5xl p-4  bg-gray-100 rounded-2xl m-2 mx-2">
        { this.state.selectedLot ?
            <>
            <div className="flex  my-2 justify-end mr-12">
            <button ref={this.ref} onClick={() => this.setAddFormOpen(true)} className="px-3 py-1 flex mt-1 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <heroIcons.HiOutlinePlus className="pr-1 text-xl"/> Ajouter une imputation </button>
            <Listbox value={this.state.selectedLot} onChange={(event) => {this.setSelectedLot(event)}}>
                {({ open }) => (
                    <>
                    <div className="w-52 relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                        <span className="block truncate">{this.state.selectedLot ? this.state.selectedLot.name : ""}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <heroIcons.HiOutlineSelector
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Listbox.Options
                        static
                        className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm "
                        >
                        {this.state.lots.map((lot, lotIdx) => (
                            <Listbox.Option
                            key={lotIdx}
                            className={({ active }) =>
                            `${
                                active
                                ? "text-amber-900 bg-amber-100"
                                : "text-gray-900"
                            }
                            cursor-default select-none hover:bg-gray-200 relative py-2 pl-10 pr-4`
                        }
                        value={lot}
                        >
                            {({ selected, active }) => (
                                <>
                                <span
                                    className={`${
                                        selected ? "font-medium" : "font-normal"
                                    } block truncate`}
                                    >
                                    {lot.name}
                                </span>
                                {selected ? (
                                    <span
                                    className={`${
                                        active ? "text-amber-600" : "text-amber-600"
                                    }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                    >
                                    <heroIcons.HiOutlineCheck
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                        />
                                    </span>
                                ) : null}
                                </>
                            )}
                            </Listbox.Option>
                        ))}
                        </Listbox.Options>
                    </Transition>
                    </div>
                </>
                )}
            </Listbox>
            </div>
            <table className="border border-collapse border-gray-900">
                <thead className="">
                    <tr>
                        <th className="border border-collapse border-gray-900">Descritptif</th>
                        <th className="border border-collapse border-gray-900">Entreprise</th>
                        <th className="border border-collapse border-gray-900">Montant HT</th>
                        <th className="border border-l-2 border-gray-900">%</th>
                        <th className="border border-collapse border-gray-900">Montant HT</th>
                        <th className="border border-collapse border-gray-900">Montant TTC</th>
                        <th className="w-10 border border-l-2 border-collapse border-gray-900">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {this.showtab()}
                </tbody>
            </table>
            </>
           : <div className="flex m-10"><p className="mx-auto">Il n'y a aucun lot veuillez en créer pour pouvoir ajouter des imputations</p></div>  }
        </div>        
        </div>
        </>
    )}
} export default withRouter(Imputations);