import React from 'react'
import { Link } from 'react-router-dom';
//import { render } from '@headlessui/react/dist/utils/render';

class Navbar extends React.Component{

    constructor(props){
        super(props);
        this.state= {
            isLog: true,

        }
    }

    componentDidMount(){
        var isLog =  window.location.pathname === "/login" ? false : true
        this.setState({isLog: isLog})
    }

    render(){
        return (
            <div className={`bg-gray-600 h-16 items-center justify-between px-5 ${this.state.isLog ? "w-screen flex" : ""}`}>
            <div className={`flex-none animate-pulse text-purple-100 font-bold ${this.state.isLog ? "w-52 text-3xl" : "pt-2 text-5xl"}`}>OPÉRA</div>
            <div className={`flex justify-center ${this.state.isLog ? null : "invisible"}`} >
                <Link className="text-white hover:text-gray-900 hover:bg-white p-3 rounded-2xl" to="/projets/1">Projets ARTS</Link>
                <Link className="text-white hover:text-gray-900 hover:bg-white p-3 rounded-2xl" to="/projets/2">Projets QUAERO</Link>
                <Link className="text-white hover:text-gray-900 hover:bg-white p-3 rounded-2xl" to="/companys/">Entreprises</Link>
            </div>
            <div className={`flex-none w-52 ${this.state.isLog ? null : "invisible"}`}>
                <Link className="text-white hover:text-gray-900 hover:bg-white p-3 rounded-2xl" to="/debug/">Un probleme ?</Link>
            </div>
            </div>
            )
    }
} export default Navbar ;
