import React, { Fragment } from 'react' 
import { withRouter} from 'react-router';
import {instance, checkToken} from '../../globalData'
import {Transition, Dialog} from '@headlessui/react'
import { Link} from 'react-router-dom';
import * as heroIcons from 'react-icons/hi'
import Select from 'react-select';

const defaultForm = {
    name: "",
    company: ""
}

var options = []
class ProjetResume extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            formOpen: false,
            formLot:[],
            selected: 0,
            form: defaultForm,
            lots: [],
            company: [],
            notes: ""
        }
        this.amount = 0
    }
    
    async getAllCompanys(){
        await checkToken()
        instance.get(`company/`).then((res)=>{
            res.data.map((company)=>{
                var value = {}
                value.value = company.id
                value.label = company.name
                options.push(value)
            })
        })
    }

    setAddFormOpen(v) {
        this.setState({formOpen: v});
    }

    async onNotesSubmit(e){
        await checkToken()
        instance.patch(`project/${this.props.match.params.projectId}/`, {notes: e.target.value})
    }

    onNotesChange(e){
        this.setState({notes:e.target.value})
    }

    async onLotSubmit(e){
        await checkToken()
        instance.patch(`lots/${this.state.lots[e.target.id].id}/`, {[e.target.name]: e.target.value})
    }

    onLotChange(e){
        var prev = this.state.lots
        prev[e.target.id][e.target.name] = e.target.value
        this.setState({lots: prev})
    }
    
    onListChange = (event) => {
        this.setState({selected: event})
    }

    onChange = (e) => {
        this.setState(prev => ({
            form:{
                ...prev.form,
                [e.target.name]:e.target.value,
            }
        }))
    };

    async submitForm(){
        await checkToken()
        instance.post("lots/",{
            "name":this.state.form.name,
            "company": this.state.selected.value,
            "project": this.props.match.params.projectId,
        }).then(()=>{
            this.componentDidMount()
        }).catch(e=>{
            alert("Une erreur d'est produite")
        })
        this.setAddFormClose();
    }

    //fermer le formulaire d'ajout d'avenant
    setAddFormClose(){
        this.setState({formOpen: false, form: defaultForm, })
    };


    AddForm = () => {
        return(
            <>
            <div className="mt-2  h-28">
            <div className="grid grid-cols-2 gap-y-4">
                    <label>Nom</label>
                    <input  name="name"
                            placeholder="Nom"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                            value={this.state.form.name}
                    
                    />
                    <label>Entreprise</label>
                    <Select
                        value={this.state.selected}
                        onChange={this.onListChange}
                        maxMenuHeight="90px"
                        className="relative"
                        menuPlacement="auto"
                        options={options}
                    />
            </div>
            </div>
            </>
        )
    }

      getAdress(i){
        return this.state.company.address ? this.state.company.address.split(",")[i] : ""
      }
      
      async getLot(lots){
        let data = []
        await checkToken()
        let r = lots.map((lot) => {
            return instance.get('detaillot/' + lot.id + "/")
            .then( res => {
                data.push(res.data[0])
            })
        });
        Promise.all(r).then(()=> {
            this.setState({lots: data});
        })
    }
    
    getLotAmount(lot){
        let amount = 0
        lot.devis.map((e) => {
            amount += e.amount
        })
        this.amount += amount
        return amount

    }

    async componentDidMount() {
        this.getAllCompanys()
        await checkToken()
        instance.get(`project/${this.props.match.params.projectId}/`)
        .then((res)=>{
            this.setState({notes: res.data.notes })
            instance.get(`ownercompany/${res.data.company}/`).then((res) =>{
                this.setState({company: res.data})
            })
        })
        await checkToken()
        instance.get('showlots/' + this.props.match.params.projectId + "/").then(
        res => {
            this.getLot(res.data);
        }
        );
    }

    tableData(){
        let data = []
        if(this.state.lots.length === 0){
            return(<td colspan="10">Il n'y a aucun lot</td>)
        }
        this.amount = 0;
        this.state.lots
            .sort((a, b) => a.id > b.id ? 1 : -1)
            .map((lot, i) => {
                data.push(
                <tr className="">
                    <td className="border-b-2 border-gray-500">{lot.company.name}</td>
                    <td className="border-b-2 border-gray-500">{lot.name}</td>
                    <td className="border-b-2 border-gray-500">{lot.devis[0] ? lot.devis[0].number: ""}</td>
                    <td className="border-b-2 border-gray-500">
                        <input  type="text"
                            name="accord"
                            id={i}
                            value={lot.accord}
                            onBlur={(event) => this.onLotSubmit(event)}
                            onChange={(event) => this.onLotChange(event)}
                            className = "appearance-none text-right border w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:bg-white focus:border-black"
                        />
                    </td>
                    <td className="border-b-2 border-gray-500">
                        <input  type="text"
                            name="signature"
                            id={i}
                            value={lot.signature}
                            onBlur={(event) => this.onLotSubmit(event)}
                            onChange={(event) => this.onLotChange(event)}
                            className = "appearance-none text-right border w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:bg-white focus:border-black"
                        />
                    </td>
                    <td className="border-b-2 border-gray-500">
                        <input  type="text"
                            name="previsionel"
                            id={i}
                            value={lot.previsionel}
                            onBlur={(event) => this.onLotSubmit(event)}
                            onChange={(event) => this.onLotChange(event)}
                            className = "appearance-none text-right border w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:bg-white focus:border-black"
                        />
                    </td>
                    <td className="border-b-2 border-gray-500">
                        <input  type="text"
                            name="delais"
                            id={i}
                            value={lot.delais}
                            onBlur={(event) => this.onLotSubmit(event)}
                            onChange={(event) => this.onLotChange(event)}
                            className = "appearance-none text-right border w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:bg-white focus:border-black"
                        />
                    </td>
                    <td className="border-b-2 border-gray-500">{this.getLotAmount(lot)}</td>
                    <td className="border-b-2 border-gray-500">
                        <textarea  rows="2" 
                            cols="6" 
                            name="observations"
                            id={i}
                            value={lot.observations}
                            onBlur={(event) => this.onLotSubmit(event)}
                            onChange={(event) => this.onLotChange(event)}
                            className = "text-sm appearance-none text-left border w-full px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:bg-white focus:border-black"
                        />
                    </td>
                    <td className="border-b-2 border-gray-500">
                    <Link 
                        className="flex text-base items-center hover:text-gray-500 rounded-full"
                        role="button"
                        to={`/lots/${this.props.match.params.projectId}/${lot.id}`}>
                        <heroIcons.HiOutlinePencil/>
                    </Link>
                    </td>
                </tr>)
            })
            data.push(
                <tr className="">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className=""></td>
                    <td className=""></td>
                    <td className=""></td>
                    <td className="font-bold text-right bg-gray-300 rounded-bl-lg">TOTAL:</td>
                    <td className="bg-gray-300 rounded-br-lg">{this.amount}</td>
                    <td className=""></td>
                </tr>
            )
            return data
        }

    render() {
        if(!this.state.company){
            return(<p>Chargement en cours</p>)
        }
        return(
            <>

        <Transition show={this.state.formOpen} as={Fragment}>
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 z-10 overflow-y-auto"
                initialFocus={this.ref}
                static
                open={this.state.formOpen}
                onClose={() => null}
            >
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>
        
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                        as="h3"
                        className="text-center text-lg font-medium leading-6 text-gray-900"
                    >
                        Ajouter un lot
                    </Dialog.Title>
                    
                    <this.AddForm/>
                    <div className="mt-4 flex justify-center">
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => this.setAddFormClose()}
                        >
                        Annuler
                        </button>
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={(e)=> this.submitForm(e)}
                        >
                        Valider
                        </button>
                        
                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition>


            <div className="grid justify-items-center">
                <div className="max-w-5xl p-4  bg-gray-100 rounded-2xl m-2">
                <h1 className="text-3xl font-bold">Récapitulatif du projet: {this.state.lots[0] ? this.state.lots[0].project.name : ""}</h1>
                <div className="flex justify-around items-center">
                <div className = "bg-gray-300 w-52 mt-4 p-4 rounded-lg justify-center">
                <p >{this.state.company.name}<br/>{this.getAdress(0)}<br/>{this.getAdress(1)}<br/><br/>N° Siret: {this.state.company.siret}</p>
                </div>
                <div className="m-2">
                    <h3 className="underline text-left my-2">Notes:</h3>
                    <textarea   rows="5" 
                                cols="40" 
                                className="" 
                                placeholder="" 
                                onBlur={(e) => this.onNotesSubmit(e)}
                                onChange={(e)=>this.onNotesChange(e)} 
                                value={this.state.notes}/>
                </div>
                <div className="m-2">
                <button ref={this.ref} onClick={() => this.setAddFormOpen(true)} className="w-56 px-3 py-1 flex m-4 mx-auto text-base items-center justify-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <heroIcons.HiOutlinePlus className="pr-1 text-xl "/> Ajouter un lot</button>
                    <Link className="w-56 px-3 py-1 flex m-4 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full"
                            to={`/imputations/${this.props.match.params.projectId}/`}
                            role="button"
                      >
                        <heroIcons.HiOutlineArrowCircleRight className="pr-1 text-xl"/>Afficher les imputations
                    </Link>
                </div>
                </div>
                <table className="table-fixed w-full mt-8 ">
                    <thead className="sticky top-0 bg-gray-500 text-white text-l py-2 pl-2">
                        <tr>
                            <td className="w-1/2 rounded-l-lg">Entreprise</td>
                            <td className="w-1/2">Désignation Travaux</td>
                            <td className="w-1/2">Devis</td>
                            <td className="w-1/2">date d'accord</td>
                            <td className="w-1/2">Date de signature</td>
                            <td className="w-1/2">Démarrage prévisionnel</td>
                            <td className="w-1/2">Délais d'intervention</td>
                            <td className="w-1/2">Montant H.T.</td>
                            <td className="w-1/2 rounded-r-lg">Observations</td>
                            <td className="w-5 bg-gray-100"></td>
                        </tr>
                    </thead>

                    <tbody className="">
                        {this.tableData()}
                    </tbody>
                </table>
                </div>
            </div>
            </>
        )
    }
} export default withRouter(ProjetResume);