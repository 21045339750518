import React, { Fragment} from 'react'
import{ checkToken, instance } from '../../../globalData'
import * as heroIcons from 'react-icons/hi'
import {Link} from 'react-router-dom'
import { Transition, Dialog} from '@headlessui/react'


var defaultCompanyForm = {
    name: "",
    address: "",
    siret: "",
}

class HomeCompany extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            companys:[],
            companyForm: defaultCompanyForm,
            openCompanyForm: false,

        }
        this.ref = React.createRef();
    }

    async componentDidMount() {
        await this.getCompanys();
      }

    onCompanyChange(e){
    this.setState(prev => ({companyForm:{
        ...prev.companyForm,
        [e.target.name]:e.target.value,
    }}))
    }

    async submitCompanyForm(){
        await checkToken();
        instance.post(`company/`, {
            "name": this.state.companyForm.name,
            "address":this.state.companyForm.address,
            "siret":this.state.companyForm.siret,
        }).then(() => {
            this.getCompanys()
            this.setState({companyForm: defaultCompanyForm})
        })
        this.setState({openCompanyForm:false})

    }

    async getCompanys(){
    await checkToken()
    instance.get('company/').then(
        res => {
        this.setState({companys: res.data})
        }
    );
    }

    CompanyForm = () => {
        return(
            <div className="mt-2">
            <div className="grid grid-cols-2 gap-y-4">
                <label>Nom de l'entreprise</label>
                <input  name="name"
                        placeholder="Nom de l'entreprise"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onCompanyChange(e)}
                        value={this.state.companyForm.name}
                />
                <label>Adresse</label>
                <input  name="address"
                        placeholder="Adresse"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onCompanyChange(e)}
                        value={this.state.companyForm.address}
                />
                <label>N° de siret</label>
                <input  name="siret"
                        placeholder="N° de siret"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onCompanyChange(e)}
                        value={this.state.companyForm.siret}
                />
            </div>
            </div>           
        )
    }
    
    render(){
        return(
            <>

        <Transition show={this.state.openCompanyForm} as={Fragment}>
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 z-10 overflow-y-auto"
                initialFocus={this.ref}
                static
                open={this.state.openCompanyForm}
                onClose={() => null}
            >
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>
        
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                        as="h3"
                        className="text-center text-lg font-medium leading-6 text-gray-900"
                    >
                        Ajouter une entreprise
                    </Dialog.Title>
                    
                    <this.CompanyForm/>
                    <div className="mt-4 flex justify-center">
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => this.setState({openCompanyForm:false})}
                        >
                        Annuler
                        </button>
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={(e)=> this.submitCompanyForm(e)}
                        >
                        Valider
                        </button>
                        
                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition>

            <div className="grid justify-items-center">
            <div className=" max-w-5xl p-4  bg-gray-100 rounded-2xl m-2">
            <div className="flex justify-between items-center">
                <div className="w-60"></div>
                <div>
                    <h1 className="text-3xl font-bold ">Liste des Entreprises</h1>
                </div>
                <div className="w-60">
                <button onClick={() => this.setState({openCompanyForm:true})} className="w-56 px-3 py-1 flex m-4 mx-auto text-base items-center justify-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <heroIcons.HiOutlinePlus className="pr-1 text-xl "/>Ajouter une entreprise</button>
                </div>
                </div>
                <table className="table-fixed w-full mt-8 ">
                    <thead className="sticky top-0 bg-gray-500 text-white text-lg p-2">
                        <tr>
                            <td className="w-1/5 rounded-l-lg">Nom de l'entreprise</td>
                            <td className="w-1/2">Adresse</td>
                            <td className="w-1/5">N° de siret</td>
                            <td className="w-10 rounded-r-lg"></td>
                        </tr>
                    </thead>

                    <tbody className="divide-y-4 divide-double">
                        {this.state.companys
                        .sort(function(a, b) {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                           })
                        .map((company) => (
                            <tr className="">
                                <td>{company.name}</td>
                                <td>{company.address}</td>
                                <td>{company.siret}</td>
                                <td className="">
                                <Link 
                                    className="text-xl  hover:text-gray-500 rounded-full"
                                    role="button"
                                    to={`/companys/${company.id}/`}>
                                    <heroIcons.HiOutlinePencil/>
                                </Link>
                                </td>
                            </tr>
                        ))
                        
                        }
                        
                    </tbody>
                </table>
            </div>
            </div>
            </>
        )
    }
} export default HomeCompany;