import React, { Fragment,} from 'react';
import {  Listbox, Transition, } from '@headlessui/react'
import * as heroIcons from 'react-icons/hi';
import {instance, checkToken} from '../../../globalData'
import { withRouter} from 'react-router';

import {Devis} from './devis';
import {Imputations} from './imputations'
import {Avancement} from './avancement'
import {Paiements} from './paiements'
 
export const lots = [
  {name: "re"},
  {name: "namne"}
]


class HomeLots extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      lots: [],
      selectedLot: {},
      lotData: {},
      lotAmount:0,
      avancements: [],
      loading: true,
      totalDevis: 0,
      situationCumul: 0,
    }
  }

  async getProgressBar(){

  }

  refreshAll = () => {
    this.getlotData(this.state.selectedLot.id);
  }

  async componentDidMount() {
    const projectId = this.props.match.params.projectId;
    await this.getLots(projectId)
    if(this.props.match.params.selectedLot){
      await this.getlotData(this.props.match.params.selectedLot)
    }
    else{
      await this.getlotData(1);
    }
    await this.getProgressBar()
  }

  async getlotData(id) {
    // get data
    var data = {}
    await checkToken()
    await instance.get('detaillot/'+ id +'/').then(
      res => {
        data = res.data[0]
      }
    )
    
    //get amount of devis 
    let amount = 0
    let avancementTotal = 0 
    var avancementArray = []
    let amountCalc = data.devis.map((devis) => {
        amount += devis.amount
        avancementTotal += devis.avancement
        avancementArray.push(devis.avancement)
    })
    Promise.all(amountCalc).then(()=>{
      console.log()
      this.setState({
                      lotData: data, 
                      loading: false, 
                      totalDevis: amount, 
                      situationCumul: avancementTotal, 
                      avancements:avancementArray,
                      lotAmount: amount,

                    })})
  }

  // fonction recupérant les lots pour les liste deroulante
  async getLots(id) {
    //init lot
    await checkToken()
    instance.get('showlots/' + id + "/").then(
      res => {
        var selected = res.data[0]
        if (this.props.match.params.selectedLot) {
          selected = res.data.find(e => e.id === this.props.match.params.selectedLot)
        }
        console.log("Selected")
        console.log(res.data[0])
        this.setState({lots: res.data, selectedLot: res.data[0]});
      }
    );
  }


  async setSelectedLot(event) {
    this.setState({selectedLot: event})
    await this.getlotData(event.id);
    await this.getProgressBar()
  }

  render() {
    console.log(this.state)
    if(this.state.loading || !this.state.selectedLot){
      return <p>Chargement en cours</p>
    }
  return (
    <div className="grid justify-items-center">
    <h1 className="m-2 text-xl">{this.state.lotData.project?.name}</h1>
    <div className=" max-w-5xl p-4  bg-gray-100 rounded-2xl m-2 mx-2">
    <div className="mx-auto m-3 w-52">
       <Listbox value={this.state.selectedLot} onChange={(event) => {this.setSelectedLot(event)}}>
         {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <span className="block truncate">{this.state.selectedLot.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <heroIcons.HiOutlineSelector
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm "
                >
                  {this.state.lots.map((lot, lotIdx) => (
                    <Listbox.Option
                      key={lotIdx}
                      className={({ active }) =>
                        `${
                          active
                            ? "text-amber-900 bg-amber-100"
                            : "text-gray-900"
                        }
                          cursor-default select-none hover:bg-gray-200 relative py-2 pl-10 pr-4`
                      }
                      value={lot}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${
                              selected ? "font-medium" : "font-normal"
                            } block truncate`}
                          >
                            {lot.name}
                          </span>
                          {selected ? (
                            <span
                              className={`${
                                active ? "text-amber-600" : "text-amber-600"
                              }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <heroIcons.HiOutlineCheck
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
    <div className="grid md:grid-cols-2 items-center">
      <div className="m-2">
        <Devis {...this.state} refreshAll={this.refreshAll}/>
      </div>
      <div className="m-2">
        <Avancement {...this.state} refreshAll={this.refreshAll}/>
      </div>
      <div className="m-2">
        <Paiements {...this.state}/>
      </div>
      <div className="m-2">
        <Imputations {...this.state} project={this.props.match.params.projectId}/>
      </div>
    </div>
  </div>
  </div>
  );};
} export default withRouter(HomeLots);