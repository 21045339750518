import axios from 'axios'
import jwt from 'jsonwebtoken'
import { Redirect } from 'react-router-dom';
// const apiUrl = "http://localhost:8000/api/"
const apiUrl = "https://api.opera.no-el.fr/api/"
export default apiUrl;

export const instance = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
});

async function refreshToken() {
    await axios.post(apiUrl + `refresh/`, {
        'refresh':localStorage.getItem('refresh') 
    }).then(res => {
        localStorage.setItem('token', res.data.access)
        instance.defaults.headers['Authorization'] = 'Bearer ' + res.data.access
    })
}

export async function checkToken() {
    if(!localStorage.getItem('token') || !localStorage.getItem('refresh')){
        window.location = "/login";
    }
    var token = localStorage.getItem('token');
    var decodedToken = jwt.decode(token, {complete: true})
    var now = new Date().getTime() / 1000;
    if(decodedToken.payload.exp < now){
        if(jwt.decode(localStorage.getItem('refresh'), {complete: true}).payload.exp < now){
            localStorage.removeItem('token')
            localStorage.removeItem('refresh')
            return (<Redirect to="/login" />)
        }
        else{
            await refreshToken()
        }
    }
    else{
    }
}



export async function checkLogin(){
    if(!localStorage.getItem('token') || !localStorage.getItem('refresh')){
        return false
    }
    await checkToken();
    instance.get().then(res => {
    });
    return false;
}