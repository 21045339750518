import React, { Fragment} from 'react'
import {checkToken, instance} from '../../globalData'
import * as heroIcons from 'react-icons/hi'
import { Transition, Dialog } from '@headlessui/react'
import { Link} from 'react-router-dom';


var defaultProjectForm = {
    name: "",
    company: "",
}

var defaultCompanyForm = {
    name: "",
    address: "",
    siret: "",
}

class HomeProject extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            projects:[],
            companyForm: defaultCompanyForm,
            openCompanyForm: false,
            projectForm: defaultProjectForm,
            openProjectForm: false,

        }
        this.ref = React.createRef();
        this.ref2 = React.createRef();
    }

    onProjectChange(e){
        this.setState(prev => ({projectForm:{
            ...prev.projectForm,
            [e.target.name]:e.target.value,
        }}))
    }
    
    onCompanyChange(e){
        this.setState(prev => ({companyForm:{
            ...prev.companyForm,
            [e.target.name]:e.target.value,
        }}))
    }

    async submitProjectForm(){
        await checkToken()
        instance.post(`project/`, {
            "name": this.state.projectForm.name,
            "company": this.props.match.params.companyId,
        }).then(() => {
            this.getProjects(this.props.match.params.companyId)
            this.setState({projectForm: defaultProjectForm})
        })
        this.setState({openProjectForm:false})
        
    }

    async submitCompanyForm(){
        await checkToken()
        instance.post(`company/`, {
            "name": this.state.companyForm.name,
            "address":this.state.companyForm.address,
            "siret":this.state.companyForm.siret,
        }).then(() => {
            this.setState({companyForm: defaultCompanyForm})
        })
        this.setState({openCompanyForm:false})

    }

    ProjectForm = () => {
        return(
            <div className="mt-2">
            <div className="grid grid-cols-2 gap-y-4">
                <label>Nom du projet</label>
                <input  name="name"
                        placeholder="Nom du projet"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onProjectChange(e)}
                        value={this.state.projectForm.name}
                />
            </div>
            </div>
        )
    }

    CompanyForm = () => {
        return(
            <div className="mt-2">
            <div className="grid grid-cols-2 gap-y-4">
                <label>Nom de l'entreprise</label>
                <input  name="name"
                        placeholder="Nom de l'entreprise"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onCompanyChange(e)}
                        value={this.state.companyForm.name}
                />
                <label>Adresse</label>
                <input  name="address"
                        placeholder="Adresse"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onCompanyChange(e)}
                        value={this.state.companyForm.address}
                />
                <label>N° de siret</label>
                <input  name="siret"
                        placeholder="N° de siret"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onCompanyChange(e)}
                        value={this.state.companyForm.siret}
                />
            </div>
            </div>           
        )
    }

    async componentDidMount() {
        await this.getProjects(this.props.match.params.companyId);
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.companyId !== this.props.match.params.companyId) {
            this.getProjects(nextProps.match.params.companyId);
        }
      }

    async getProjects(id){
    await checkToken();
    instance.get(`detailproject/?company=${id}`).then(
        res => {
        this.setState({projects: res.data})
        }
    );
    }
    
    render(){
        return(
            <>

        <Transition show={this.state.openCompanyForm} as={Fragment}>
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 z-10 overflow-y-auto"
                initialFocus={this.ref}
                static
                open={this.state.openCompanyForm}
                onClose={() => null}
            >
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>
        
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                        as="h3"
                        className="text-center text-lg font-medium leading-6 text-gray-900"
                    >
                        Ajouter une entreprise
                    </Dialog.Title>
                    
                    <this.CompanyForm/>
                    <div className="mt-4 flex justify-center">
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => this.setState({openCompanyForm:false})}
                        >
                        Annuler
                        </button>
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={(e)=> this.submitCompanyForm(e)}
                        >
                        Valider
                        </button>
                        
                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition>



            <Transition show={this.state.openProjectForm} as={Fragment}>
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 z-10 overflow-y-auto"
                initialFocus={this.ref2}
                static
                open={this.state.openProjectForm}
                onClose={() => null}
            >
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>
        
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                        as="h3"
                        className="text-center text-lg font-medium leading-6 text-gray-900"
                    >
                        Ajouter un Projet
                    </Dialog.Title>
                    <this.ProjectForm/>
                    <div className="mt-4 flex justify-center">
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => this.setState({openProjectForm:false})}
                        >
                        Annuler
                        </button>
                        <button
                        type="button"
                        className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={(e)=> this.submitProjectForm(e)}
                        >
                        Valider
                        </button>
                        
                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition>


            <div className="grid justify-items-center">
            <div className="max-w-5xl p-4  bg-gray-100 rounded-2xl m-2">
                <div className="flex justify-between items-center">
                <div>
                <button onClick={() => this.setState({openProjectForm:true})} className="w-56 px-3 py-1 flex m-4 mx-auto text-base items-center justify-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <heroIcons.HiOutlinePlus className="pr-1 text-xl "/> Ajouter un projet</button>
                </div>
                <div>
                    <h1 className="text-3xl font-bold">Liste des Projets</h1>
                </div>
                <div>
                <button onClick={() => this.setState({openCompanyForm:true})} className="w-56 px-3 py-1 flex m-4 mx-auto text-base items-center justify-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <heroIcons.HiOutlinePlus className="pr-1 text-xl "/>Ajouter une entreprise</button>
                </div>
                </div>
                <table className="table-fixed w-full mt-8 ">
                    <thead className="sticky top-0 bg-gray-500 text-white text-lg p-2">
                        <tr>
                            <td className="w-1/5 rounded-l-lg">Nom</td>
                            <td className="w-1/5">Montant Total</td>
                            <td className="w-1/2">Progression</td>
                            <td className="w-1/12 rounded-r-lg">Editer</td>
                        </tr>
                    </thead>

                    <tbody className="">
                        {this.state.projects.map((project) => (
                            <tr className="">
                                <td>{project.name}</td>
                                <td>{project.totalAmountHT}</td>
                                <td className="">
                                    <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-300">
                                        <div style={{width: project.totalAmountHT === 0 ? 0 : parseInt(project.totalPaidHT*100/(project.totalAmountHT)) + "%"}} class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-500"></div>
                                    </div>
                                </td>
                                <td className="">
                                <Link 
                                    className="px-3 py-1 flex ml-3 text-base items-center hover:text-gray-500 rounded-full"
                                    role="button"
                                    to={`/projetresume/${project.id }/`}>
                                    <heroIcons.HiOutlinePencil/>
                                </Link>
                                </td>
                            </tr>
                        ))
                        
                        }
                        
                    </tbody>
                </table>
            </div>
            </div>
        </>
        )
    }
} export default HomeProject;