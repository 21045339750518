import {instance, checkToken} from '../../../globalData'
import React, { Fragment,} from 'react';
import * as heroIcons from 'react-icons/hi';
import { Disclosure, Transition, Dialog} from '@headlessui/react'
import Select from 'react-select';

var month = [
  {label: "Janvier", value:"01"},
  {label: "Février", value:"02"},
  {label: "Mars", value:"03"},
  {label: "Avril", value:"04"},
  {label: "Mai", value:"05"},
  {label: "Juin", value:"06"},
  {label: "Juillet", value:"07"},
  {label: "Août", value:"08"},
  {label: "Septembre", value:"09"},
  {label: "Octobre", value:"10"},
  {label: "Novembre", value:"11"},
  {label: "Decembre", value:"12"},
]

var year = []

const defaultForm = {
    year:"",
    month:"",
    version:"",
    avenants:[],
  }

export class Paiements extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedCertificat:0,
            selectedLot: 1,
            formOpen: false,
            form:defaultForm,
            certificats:[]
        }
    }

    initValue(){
      for(var i = 1; i < 32; i++){
          var y={}
          y.value = ("20" + (10 + i))
          y.label = ("20" + (10 + i))
          year.push(y)
      }
  }

    async getCertificat(){
      await checkToken()
      await instance.get(`certificatpaimement/?lot=${this.props.selectedLot.id}`)
      .then((e) => {
        var certificats = []
        var getCertificats = e.data.map((c) => {
          var cert = {}
          cert.id = c.id
          cert.version = c.version
          cert.date = c.date
          cert.amount = c.amount
          certificats.push(cert)
        })
        Promise.all(getCertificats).then(()=>{
          this.setState({certificats: certificats})
        })
      })
    }

    async getAvenants(id) {
      var avenants = []
      await instance.get(`avenantcertificat/?certificat=${id}`)
      .then((res) => {
        let getAllAvenants = res.data.map((e) => {
          avenants.push(e)
        })
        Promise.all(getAllAvenants).then(() =>{
          this.setState(prev => ({
            form:{
                ...prev.form,
                ["avenants"]:avenants,
            }
        }))
        })
      }) 
    }

    onAvancementChange(e){
      var avenants = this.state.form.avenants
      avenants[e.target.id].avancement = e.target.value
      this.setState(prev =>({
        form:{
          ...prev.form,
          "avenants":avenants
        }
      }))
    }

    onSelectChange(e, name){
      this.setState(prev => ({
          form:{
              ...prev.form,
              [name]:e.value,
          }
      }))}

    onChange(e){
      this.setState(prev => ({
        form:{
            ...prev.form,
            [e.target.name]:e.target.value,
        }
    }))
    }

    EditForm = () => {
      var avenants = []
      this.state.form.avenants.sort((a, b) => a.order > b.order ? 1 : -1).map(async (a,i) => {
        avenants.push(
        <>
        <label>{a.name}</label>
          <input  name={a.name}
                  id={i}
                  placeholder="avenant"
                  className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                  onChange={(e)=>this.onAvancementChange(e)}
                  value={a.avancement}
                  
                  />
          </>)
      })
        return(
          <>
            <div className="mt-2">
            <div className="grid grid-cols-2 gap-y-4">
              <label>Version</label>
                <input  name="version"
                        placeholder="Version"
                        className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                        onChange={(e)=>this.onChange(e)}
                        value={this.state.form.version}
                
                />
              <label>Date du certificat</label>
                <div className="flex justify-center">
                <div>
                
                <Select
                    className= "w-32 text-sm"
                    maxMenuHeight="250px"
                    placeholder="Mois"
                    name="month"
                    value={month[Number.parseInt(this.state.form.month) - 1]}
                    onChange={(e)=>this.onSelectChange(e, "month")}
                    options={month}
                /></div>
                <div><Select
                    className="w-24 text-sm"
                    maxMenuHeight="250px"
                    placeholder="Année"
                    name="year"
                    value={year[Number.parseInt(this.state.form.year) - 2011]}
                    onChange={(e)=>this.onSelectChange(e, "year")}
                    options={year}
                /></div>
                </div>
                {avenants}
            </div>
          </div>
          </>
        )
    }

    async generate(){
        await checkToken()
        await instance.post('certificatpaimement/',{
            "version":1,
            "lot":this.props.selectedLot.id,
            "amount": 0
        }).then((res) =>{
            this.props.lotData.devis
            .map(async (e,i) => {

                await checkToken()
                await instance.post('avenantcertificat/',{
                    "name":e.name,
                    "order": i,
                    "amount":e.amount,
                    "avancement":e.avancement,
                    "certificat":res.data.id,
                })
            })
        })
        this.getCertificat()
    }

    componentDidMount(){
      this.initValue()
      this.getCertificat()
    }

    openForm(id, i){ 
      this.getAvenants(id)
      this.setState({formOpen: true, selectedCertificat: i})
      this.setState(prev => ({
        form: {
          ...prev.form,
          "version": this.state.certificats[i].version,
          "month":this.state.certificats[i].date.split("-")[1],
          "year":this.state.certificats[i].date.split("-")[0]
        }
      }))
    }

    async submitForm(){
      await checkToken()
      await instance.patch(`certificatpaimement/${this.state.certificats[this.state.selectedCertificat].id}/`,{
        "version": this.state.form.version,
        "date": this.state.form.year + "-" + this.state.form.month + "-01"
      }).then(() =>{
        this.getCertificat()
      })
      this.state.form.avenants.map(async (e) => {
        await checkToken()
        await instance.patch(`avenantcertificat/${e.id}/`,{
          "avancement": e.avancement
        })
      })
      this.closeForm()
    }

    closeForm(){
      this.setState({formOpen: false, form: defaultForm})
    }

    componentWillReceiveProps(props){
      if(props.selectedLot.id !== this.state.selectedLot){
        this.getCertificat()
      }
    }

    showTab = () => {
      if(!this.state.certificats[0]){
        return <p>Il n'y a aucun paiment</p>
      }
      var tab =[]
      this.state.certificats.map((c,i)=>{
        tab.push(
        <tr>
          <td className="border border-black text-center">{i+1}</td>
          <td className="border border-black text-center">Émis</td>
          <td className="border border-black text-center">{c.date}</td>
          <td className="border border-black text-right">{c.amount}</td>
          <td className=""><button onClick={() => this.openForm(c.id, i)} className="text-xl bg-gray-400 p-0.5 hover:bg-gray-500 rounded-full ml-3"><heroIcons.HiDotsHorizontal/></button></td>
        </tr>)
        })
      return (
      <>
      <table className="table-auto w-full">
      <thead>
        <tr>
          <th className="">N°</th>
          <th className="">État</th>
          <th className="">Date</th>
          <th className="">Montant HT</th>
          <th className=""></th>
        </tr>
      </thead>
      <tbody className="">
        {tab}
        </tbody>
        </table>
        <h2 className="font-bold mt-2">Total PAIEMENTS HT:  90 000</h2>
        </>)
    }

    render(){

    return(
      <>
              <Transition show={this.state.formOpen} as={Fragment}>
        <Dialog
            as="div"
            id="modal"
            className="fixed inset-0 z-1 overflow-y-auto"
            initialFocus={this.ref}
            static
            open={this.state.formOpen}
            onClose={() => null}
        >
            <div className="min-h-screen px-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
    
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
            >
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                    Editer un certificat de paiment
                </Dialog.Title>
                
                <this.EditForm/>

                <div className="flex justify-center w-full mt-5">
                <button
                    type="button"
                    className="w-36 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.closeForm()}
                    >
                    Générer le pdf
                    </button>
                </div>
                <div className="mt-4 flex justify-center">
                    <button
                    type="button"
                    className="w-36 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.closeForm()}
                    >
                    Fermer
                    </button>
                    <button
                    type="button"
                    className="w-36 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={(e)=> this.submitForm(e)}
                    >
                    Mettre à jour
                    </button>
                    
                </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition>


        <div className="w-full">
          <div className="w-full max-w-md p-2 mx-auto bg-gray-300 rounded-2xl">
              <h2 className="font-bold ">Paiements</h2>
                  <Disclosure as="div" className="mt-2">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <span>Paiements</span>
                        <heroIcons.HiOutlineChevronDown
                          className={`${
                            open ? "transform rotate-180" : ""
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-coolGray-500">
                          {this.showTab()}
                        <button className="px-3 py-1 flex m-4 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full" onClick={() => this.generate()}><heroIcons.HiOutlinePlus className="pr-1 text-xl"/>Générer un certificat de paiment</button>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
        </div>
        </>)}
};