import Navbar from './components/navbar'
import React from 'react'
import HomeCompany from './components/projects/company/homeCompany'
import HomeProject from './components/projects/homeProject'
import HomeLots from './components/projects/lots/homeLots'
import CertificatPaiement from './components/projects/certificatpaiement'
import ShowCompany from './components/projects/company/showCompany'
import './App.css';
import Imputations from './components/projects/imputations/imputations'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import ProjetResume from './components/projects/projetResume'
import DebugPage from './pages/debug'
import { LoginPage } from './pages/login'


// export const Route = ({
//   component: Component,
//   ...rest
// }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => {
//         if (auth.isAuthenticated()) {
//           console.log("connected")
//           return <Component {...props} />;
//         } else {
//           console.log("not connected")
//           return (
//             <Redirect
//               to="/login"
//             />
//           );
//         }
//       }}
//     />
//   );
// };


function App() {
  return (

    <div className="App">
      
      {/* <HomeProject /> */}
      {/* <HomeCompany /> */}
      {/* <Imputations projectId="1"/> */}
      <Navbar/>
      <Switch>
          {/* <Route exact path="/">
            <HomeLots projectId="1" projectName="Verneuil"/>
          </Route> */}
          <Redirect exact from= "/" to="/projets/1" />
          <Route exact path="/projets/:companyId" component={HomeProject}/>
          <Route exact path="/pdf" component={CertificatPaiement}/>
          <Route exact path="/lots/:projectId?/:selectedLot?" component={HomeLots}/>
          <Route exact path="/projetresume/:projectId" component={ProjetResume}/>
          <Route exact path="/imputations/:projectId" component={Imputations}/>
          <Route exact path="/companys/" component={HomeCompany}/>
          <Route exact path="/companys/:companyId" component={ShowCompany} />
          <Route exact path="/debug/" component={DebugPage} />
          <Route exact path="/login/" component={LoginPage}/>
      </Switch>
      </div>
  );

    

}

export default App;