import React from 'react';
import { checkToken, instance } from '../globalData';



class DebugPage extends React.Component {
    constructor(props){
        super(props);
        this.state={
            text: ""
        }
    }

    onChange = (e) => {
        this.setState({text: e.target.value})
    }

    onBlur = async () => {
        await checkToken()
        console.log("ici")
        console.log(this.state)
        instance.patch(`debug/1/`, {
            "text":this.state.text
        })
    }

    async componentDidMount(){
        await checkToken()
        instance.get(`debug/1/`).then((res) => {
            this.setState({text: res.data.text}) 
        })
    }

    render(){
        return(
            <div className="mx-auto max-w-5xl p-4  bg-gray-100 rounded-2xl m-2">
                <h1 className="font-bold text-2xl">List des Bugs et éléments à ajouter</h1>
                <p>Pour enregistrer les modifications il suffit de cliquer en dehors de la zone de texte</p>
                <textarea
                    className="my-4 border w-full h-screen p-2 border-black"
                    value={this.state.text}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    />
            </div>
        )
    }
} export default DebugPage 