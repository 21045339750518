import axios from 'axios';
import React from 'react'
import apiUrl from '../globalData';


var defaultLoginForm = {
    user: "",
    pass: ""
}

export class LoginPage extends React.Component {
    constructor(props){
        super(props);
        this.state={
            loginForm: defaultLoginForm,
            loginError: ""
        }
    }

    onLoginChange(e){
        this.setState(prev => ({loginForm:{
            ...prev.loginForm,
            [e.target.name]:e.target.value,
        }}))
    }

    login(e){
        axios.post(apiUrl + `auth/`, {
            username: this.state.loginForm.user,
            password: this.state.loginForm.pass,
        }).then((res)=>{
            if(res.data.access){
                localStorage.setItem('token', res.data.access);
                localStorage.setItem('refresh', res.data.refresh);
                window.location = "/"
            }
            else{
                this.setState({loginError: "Erreur d'identifiant ou de mot de passe"})
            }
        }).catch(e => {
            this.setState({loginError: "Erreur d'identifiant ou de mot de passe"})
        })
    }

    render() {
        return(
            <>
            <div className="grid justify-items-center">
            <div className="max-w-5xl p-4  bg-gray-100 rounded-2xl m-10">
                <h1 className="m-2 font-bold text-3xl"> Connexion </h1>
                <div className="mt-10 grid ">
                    <input  name="user"
                            placeholder="Identififant"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onLoginChange(e)}
                            value={this.state.loginForm.user}
                    />
                </div>
                <div className="mt-4 mb- grid">
                    <input  name="pass"
                            type="password"
                            placeholder="Mot de passe"
                            className="pl-4 mb-5 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onLoginChange(e)}
                            value={this.state.loginForm.pass}
                    />
                <p className="text-red-600 ">{this.state.loginError}</p>
                </div>
                <button
                        type="button"
                        className="w-20 inline-flex justify-center px-14 py-2 ml-4 text-sm font-medium text-gray-900 bg-gray-300 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={(e)=> this.login(e)}
                        >
                        Connexion
                        </button>
            </div>
            </div>
            </>
        )
    }

} export default LoginPage;