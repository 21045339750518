import React, { Fragment } from 'react';
import * as heroIcons from 'react-icons/hi';
import { Disclosure, Transition, Dialog} from '@headlessui/react'
import DownloadLink from "react-download-link";
import { instance, checkToken } from '../../../globalData'
import Select from 'react-select';

const defaultForm = {
    title: "",
    number: "",
    date: "",
    file:"",
    amount: '',
    day:0,
    month: 0,
    year: 0,
    filesPath: [],
    filesId: []
}

var day = []

var month = [
    {label: "Janvier", value:"01"},
    {label: "Février", value:"02"},
    {label: "Mars", value:"03"},
    {label: "Avril", value:"04"},
    {label: "Mai", value:"05"},
    {label: "Juin", value:"06"},
    {label: "Juillet", value:"07"},
    {label: "Août", value:"08"},
    {label: "Septembre", value:"09"},
    {label: "Octobre", value:"10"},
    {label: "Novembre", value:"11"},
    {label: "Decembre", value:"12"},
]

var year = []

export class Devis extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            formOpen: false,
            form:defaultForm,
            showOpen: false,
            showDevis: 0,
            filePath:"",
            hasFile:false,
            isMarche:false,
        }
        this.ref = React.createRef();
        this.ref2 = React.createRef();
        this.fileReference = React.createRef();
        this.fileUpdateReference = React.createRef();
    }

    onSelectChange(e, name){
        this.setState(prev => ({
            form:{
                ...prev.form,
                [name]:e.value,
            }
        }))
    }

    async getFiles(id) {
        await checkToken()
        instance.get(`filedevis/?devis=${id}`)
        .then((res)=>{
            if(res.data.length === 0){
                this.setState({hasFile: false, fileId:0})
            }
            else{
                this.setState({hasFile:true, filePath: res.data[0].filePath, fileId:res.data[0].id})
            }
        })
    }

    async deleteDocument(id){
        this.setState({showOpen: false})
        await checkToken()
        instance.delete(`devis/${id}/`).then(() => this.props.refreshAll())
    }

    initValue(){
        for(var i = 1; i < 32; i++){
            var d={}
            var y={}
            y.value = ("20" + (10 + i))
            y.label = ("20" + (10 + i))
            if(i < 10){
                d.value = "0" + i
            }
            else{
                d.value = "" + i
            }
            d.label = "" + i
            day.push(d)
            year.push(y)
        }
    }

    async fileUploadAction(e) {
        let newArray = [...this.state.form.filesPath, e.target.files[0]]
        this.setState(prev => ({
            form:{
                ...prev.form,
                ['filesPath']:newArray,
            }
        }))
        let data = new FormData();
        data.append('filePath', e.target.files[0]);
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                console.log(`${loaded}kb of ${total}kb | ${percent}%`) 
            }
        }
        await checkToken()
        instance.post("filedevis/", data, options).then(
            res => {
                let newId = [...this.state.form.filesId, res.data.id]
                this.setState(prev => ({
                    form:{
                        ...prev.form,
                        ['filesId']:newId,
                    }
                }))
            }
        )
    }


    async fileReuploadAction(e) {
        // let newArray = [...this.state.form.filesPath, files[0]]
        // this.setState(prev => ({
        //     form:{
        //         ...prev.form,
        //         ['filesPath']:newArray,
        //     }
        // }))
        let data = new FormData();
        data.append('filePath', e.target.files[0]);
        
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                console.log(`${loaded}kb of ${total}kb | ${percent}%`) 
            }
        }
        if(this.state.fileId === 0){
                data.append('devis', this.props.lotData.devis[this.state.showDevis]?.id)
                await checkToken()
                instance.post("filedevis/", data, options).then(
                    res => {
                        this.setState({filePath: res.data.filePath, showOpen:false})
                    }
            )
        }
        else{
        await checkToken()
        instance.patch(`filedevis/${this.state.fileId}/`, data, options).then(
            res => {
                this.setState({filePath: res.data.filePath, showOpen:false})
            }
        )}
    }

    fileClickAction = () =>this.fileReference.current.click();
    fileUpdateClickAction = () =>this.fileUpdateReference.current.click();

    showDevis = () => {
        if(!this.props.lotData.devis || this.props.lotData.devis.length === 0){
            return(<p>Il n'y a aucun Marché</p>);
        }
        else{
            return(
            <table className="table-fixed ">    
                <thead>
                    <tr>
                        <th className="w-1/3">Titre</th>
                        <th className="w-1/3">Date</th>
                        <th className="w-1/3">Montant HT</th>
                        <th className="w-1/4"></th>
                    </tr>
                    </thead>
                <tbody>
                <tr>
                    <td className="border border-black">{this.props.lotData.devis[0].name}</td>
                    <td className="border border-black">{this.props.lotData.devis[0].date}</td>
                    <td className="border border-black">{this.props.lotData.devis[0].amount}</td>
                    <td className="">
                        <button className="text-xl bg-gray-400 p-1 hover:bg-gray-500 rounded-full ml-3"
                                onClick={() => {this.getFiles(this.props.lotData.devis[0].id)
                                                this.setState({isMarche:true,showOpen: true, showDevis: 0})} }>
                            <heroIcons.HiOutlineDocumentText/>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            )
        }
    }

    showAvenants = () => {
        let content =[];
        if(this.props.lotData.devis?.length < 2){
            return (<p>Il n'y a aucun Avenant</p>);
        }
        else {
                this.props.lotData.devis?.slice(1).map((av, id) => {

                    content.push(
                        <tr>
                        <td className="border border-black">{av.name}</td>
                        <td className="border border-black">{av.date}</td>
                        <td className="border border-black">{av.amount}</td>
                        <td className="">
                            <button  className="text-xl bg-gray-400 p-1 hover:bg-gray-500 rounded-full ml-3"
                                        onClick={() => {this.getFiles(av.id)
                                            this.setState({isMarche:false, showDevis: (id+1), showOpen: true})}}>
                                        <heroIcons.HiOutlineDocumentText />
                            </button>
                        </td>
                    </tr>
                )
            })
        }
        return (
            <>
            <table className="table-fixed ">
                <thead>
                    <tr>
                    <th className="w-1/3">Titre</th>
                    <th className="w-1/3">Date</th>
                    <th className="w-1/3">Montant HT</th>
                    <th className="w-1/4"></th>
                    </tr>
                </thead>
                <tbody className="">
                    {content}
                </tbody>
                </table>
                <h2 className="font-bold mt-2">Total avenants HT:  {this.props.lotAmount - this.props.lotData?.devis[0].amount}</h2>
                </>
        );
    }

    //ouvrir le formulaire d'ajout d'avenant
    setAddFormOpen(v) {
        this.setState({formOpen: v});
    }

    //fermer le formulaire d'ajout d'avenant
    setAddFormClose(){
        // this.setState({formOpen: false, form: defaultForm,})
        this.setState({formOpen: false, form: defaultForm})
    };

    async submitForm(){
        var date = this.state.form.year + "-" + this.state.form.month + "-" + this.state.form.day
        await checkToken()
        instance.post("devis/",{
            "name":this.state.form.title,
            "date": date,
            "number": this.state.form.number,
            "version": '1',
            "lotId":this.props.lotData.devis.length + 1,
            "amount": this.state.form.amount.replace(" ", ""),
            "lot": this.props.selectedLot.id


        }).then((res)=>{
            this.state.form.filesId.map((fileId) => {
                instance.patch("filedevis/"+fileId +  "/", { devis:res.data.id})
            }) 

            // refresh is parent function
            this.setState({formOpen: false})
            this.props.refreshAll()
        }).catch(e=>{
            alert("Erreur")
        });
    }

    onChange = (e) => {
        // this.setState({ form:{[e.target.name]: e.target.value }})
        this.setState(prev => ({
            form:{
                ...prev.form,
                [e.target.name]:e.target.value,
            }
        }))
    };

    // onDateChange = (e) => {
    //     var value = e.target.value
    //     value = value.replace(/\D/g,'')
    //     console.log(e.target.name)
    //     if(value < 99999999){
    //         if(value > 9999){
    //             value = value.slice(0,2) + "/" + value.slice(2,4) + "/" + value.slice(4)
    //         }
    //         else if(value > 99){
    //             value = value.slice(0,2) + "/" + value.slice(2)
    //         }
    //         this.setState(prev => ({
    //             form:{
    //                 ...prev.form,
    //                 [e.target.name]:value,
    //             }
    //         }))
    //     }
    // }
    //formulaire d'ajout d'avenant qui sera dans la boite de dialogue
    AddForm = () => {
        let listFiles = []
        if(this.state.form.filesPath.length !== 0){
            listFiles.push(<p className="text-l underline">Liste des documents envoyés: </p>)
        }
        this.state.form.filesPath.map((e) => {
            listFiles.push(<p>{e.name}</p>)
        })
        return(
            <>
            <div className="mt-2">
            <div className="grid grid-cols-2 gap-y-4">
                    <label>Nom</label>
                    <input  name="title"
                            placeholder="Nom"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                            value={this.state.form.title}
                    
                    />
                    <label>Date du devis</label>
                    <div className="flex justify-between">
                    <div><Select
                        className="w-20 text-sm"
                        maxMenuHeight="250px"
                        placeholder="Jour"
                        name="day"
                        value={day[Number.parseInt(this.state.form.day) -  1]}
                        onChange={(e)=>this.onSelectChange(e, "day")}
                        options={day}
                    /></div>
                    <div>
                    
                    <Select
                        className="w-24 text-sm"
                        maxMenuHeight="250px"
                        placeholder="Mois"
                        name="month"
                        value={month[Number.parseInt(this.state.form.month) - 1]}
                        onChange={(e)=>this.onSelectChange(e, "month")}
                        options={month}
                    /></div>
                    <div><Select
                        className="w-24 text-sm"
                        maxMenuHeight="250px"
                        placeholder="Année"
                        name="year"
                        value={year[Number.parseInt(this.state.form.year) - 1]}
                        onChange={(e)=>this.onSelectChange(e, "year")}
                        options={year}
                    /></div>
                    </div>
                    <label>N° du devis</label>
                    <input  name="number"
                            placeholder="Numéro du devis"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                            value={this.state.form.number}
                    
                    />                    
                    <label>Montant HT</label>
                    <input  name="amount"
                            placeholder="1000 €"
                            className="pl-4 rounded-full border-2 border-gray-300 focus:outline-none"
                            onChange={(e)=>this.onChange(e)}
                            value={this.state.form.amount}
                    
                    />
                    <div className="col-span-2">
                    {listFiles}
                    </div>
                    <div className="col-span-2 mx-auto">
                
                    
                    <div>
                        <input
                            hidden
                            name="fileInput"
                            required=""
                            type="file"
                            // className="{className}"
                            ref={this.fileReference}
                            onChange={(e) => this.fileUploadAction(e)}
                        /> 
                        <div className="flex items-center">
                        <button
                            onClick={this.fileClickAction} 
                            className="px-3 py-1 flex m-4 text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full ">
                            <heroIcons.HiUpload className="pr-1 text-xl"/>Envoyer le devis
                        </button>
                        
                        </div>
                    </div>
                    </div>
                                
                    
            </div>
            </div>
            </>
        )
    }



    componentDidMount(){
        this.initValue()
    }

    render() {
    return(
        <>

        {/* Afficher devis */}

        <Transition show={this.state.showOpen} as={Fragment}>
        <Dialog
            as="div"
            id="modal"
            className="fixed inset-0 z-1 overflow-y-auto"
            initialFocus={this.ref2}
            static
            open={this.state.showOpen}
            onClose={() => null}
        >
            <div className="min-h-screen px-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
    
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
            >
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                   {this.props.lotData.devis.length != 0 ? this.props.lotData.devis[this.state.showDevis].name : ""}
                </Dialog.Title>
                
                <div className="flex justify-left">
                  <label>Date : {this.props.lotData.devis.length != 0  ? this.props.lotData.devis[this.state.showDevis].date.split("-")[2] + " " + month[parseInt(this.props.lotData.devis[this.state.showDevis].date.split("-")[1]) - 1].label + " " + this.props.lotData.devis[this.state.showDevis].date.split("-")[0] : "ERROR"}</label>
                </div>
                <div className="flex justify-left">
                  <label>Montant : {this.props.lotData.devis.length != 0  ?  this.props.lotData.devis[this.state.showDevis].amount : "ERROR"}</label>
                </div>
                <div>
                    <input
                        hidden
                        name="fileInput"
                        required=""
                        type="file"
                        // className="{className}"
                        ref={this.fileUpdateReference}
                        onChange={(e) => this.fileReuploadAction(e)}
                    />
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={this.fileUpdateClickAction} 
                        className={`px-3 py-1 flex m-4 text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full`}
                        
                        >
                        <heroIcons.HiUpload className="pr-1 text-xl"/>Envoyer un {this.state.hasFile ? "nouveau"  : ""} devis
                    </button>        
                </div>
                <div className="mt-4 flex justify-center">
                    {console.log(this.props.lotData)}
                <button
                    type="button"
                    className={`${this.state.isMarche ? "invisible w-0 h-0 m-0 p-0 absolute" : ""} w-30 inline-flex justify-center px-4 py-2 mr-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-50`} 
                    onClick={() => this.deleteDocument(this.props.lotData.devis.length != 0 ? this.props.lotData.devis[this.state.showDevis].id : 0)}
                    visible={this.state.isMarche}
                    >
                    Supprimer {this.props.lotData.devis.length != 0 ? this.props.lotData.devis[this.state.showDevis].name : ""}
                    </button>
                {/* <button
                    type="button"
                    className="w-30 inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.setShowClose()}
                    >
                    Télécharger le document
                    </button> */}

                    <DownloadLink
                      label="Télécharger le devis"
                      tagName="button"
                      // @ts-ignore
                      style={{}}
                      className={`${!this.state.hasFile ? "invisible w-0 h-0 m-0 p-0 absolute" : "w-30 inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"} `} 
                      filename={this.state.filePath}
                      exportFile={() => "My cached data"}
                      disabled={this.state.hasFile}
                  />
                </div>
                <div className="mt-4 flex justify-center">
                    
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={()=> this.setState({showOpen:false})}
                    >
                    OK
                    </button>
                    
                </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition>

        {/* Afficher */}

        <Transition show={this.state.formOpen} as={Fragment}>
        <Dialog
            as="div"
            id="modal"
            className="fixed inset-0 z-1 overflow-y-auto"
            initialFocus={this.ref}
            static
            open={this.state.formOpen}
            onClose={() => null}
        >
            <div className="min-h-screen px-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
    
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
            >
                &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                    Ajouter un {this.props.lotData.devis?.length === 0 ? "marché" : "avenant"}
                </Dialog.Title>
                
                <this.AddForm/>
                <div className="mt-4 flex justify-center">
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 mr-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => this.setAddFormClose()}
                    >
                    Annuler
                    </button>
                    <button
                    type="button"
                    className="w-20 inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={(e)=> this.submitForm(e)}
                    >
                    Valider
                    </button>
                    
                </div>
                </div>
            </Transition.Child>
            </div>
        </Dialog>
        </Transition>
        <div className="w-96">
                <div className="w-full max-w-md p-2 mx-auto bg-gray-300 rounded-2xl">
                <h2 className="font-bold ">Marchés</h2>
                <div className="m-2 ">
                    {this.showDevis()}
                    </div>
                    <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                            <span>Avenants</span>
                            <heroIcons.HiOutlineChevronDown
                            className={`${
                                open ? "transform rotate-180" : ""
                            } w-5 h-5 text-white`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-coolGray-500">
                        {this.showAvenants()}
                            <button ref={this.ref} onClick={() => this.setAddFormOpen(true)} className="px-3 py-1 flex m-4 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full "><heroIcons.HiOutlinePlus className="pr-1 text-xl"/>Ajouter un {this.props.lotData.devis.length === 0 ? "marché" : "avenant"}</button>
                        </Disclosure.Panel>
                        </>
                    )}
                    </Disclosure>
                
                    <h3 className="font-bold my-2">Montant Total (Marché + Avenants): {this.props.lotAmount } €</h3>
                </div>
                </div>
                </>
        )
    }
};