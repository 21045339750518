import { Component } from "react";
import { Disclosure} from '@headlessui/react'
import * as heroIcons from 'react-icons/hi';
import {instance, checkToken} from '../../../globalData';



export class Avancement extends Component{

    constructor(props){
        super(props);
        this.state = {
            selelectedLot: {},
            avancements: props.avancements,
            situationCumul: props.situationCumul,

        }
    }
    updateAvancement = (e) => {
        const copy = this.state.avancements.slice()
        copy[e.target.name] = e.target.value
        this.setState({avancements:copy})
    }
    updateCumul(){
        var totalCumul = 0
        for(var i = 0;i<this.state.avancements.length;i++){
            totalCumul += parseInt(this.state.avancements[i])
        }
        this.setState({situationCumul: totalCumul})
    }

    async sendAvancement(e) {
      await checkToken()
      instance.put("devis/" + e.target.id + "/", {
            "avancement":e.target.value
        }
        )
        this.updateCumul()
      }

    componentWillReceiveProps(props){
      if(props.avancements !== this.state.avancements){
        this.setState({avancements: props.avancements})
      }
      if(props.situationCumul !== this.state.situationCumul){
        this.setState({situationCumul: props.situationCumul})
      }
      if(props.totalDevis !== this.state.totalDevis){
        this.setState({totalDevis: props.totalDevis})
      }
    }

    showAvancements = () => {
        if(!this.props.lotData.devis || this.props.lotData.devis.length === 0){
            return(<p>Ajoutez un devis pour pouvoir editer les avancements</p>);
        }
        else{
            var content = []
            for(var i = 0;i<this.props.lotData.devis.length;i++){
                content.push(
                <>
                <tr>
                    <td className="border border-black text-left">{this.props.lotData.devis[i].name}</td>
                    <td className="border border-black p-0">
                        <input  type="text"
                                name={i}
                                id={this.props.lotData.devis[i].id}
                                value={this.state.avancements[i]}
                                onBlur={(event) => this.sendAvancement(event)}
                                onChange={(event) => this.updateAvancement(event)}
                                className = "appearance-none text-right border w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:bg-white focus:border-black"
                                />
                    </td>
                </tr>
                </>)
            }
            return(
                <>
                <table className="table-fixed">
                <thead>
                  <tr>
                    <th className="w-1/6">Titre</th>
                    <th className="w-1/6">Montant HT</th>
                  </tr>
                </thead>
                <tbody className="">
                {content}
                  </tbody>
                  </table>
                  <h2 className="font-bold mt-2">Situation cumulé HT: {this.state.situationCumul}</h2>
                </>
            )
        }
    }
    render(){
    return(
        <div className="w-full">
        <div className="w-full max-w-md p-2 mx-auto bg-gray-300 rounded-2xl">
            <h2 className="font-bold ">Avancement</h2>
                <Disclosure as="div" className="mt-2">
                {({ open }) => (
                    <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span>Avancement</span>
                      <heroIcons.HiOutlineChevronDown
                        className={`${
                            open ? "transform rotate-180" : ""
                        } w-5 h-5 text-white`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel as="div" className="px-4 pt-4 pb-2 text-sm text-coolGray-500">
                    {this.showAvancements}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <div className=" pt-1">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200">
                      Avancement Global
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-gray-600">
                      {parseInt(this.props.totalDevis !==0 ? this.state.situationCumul*100/this.props.totalDevis : 0)} %
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                  <div style={{width: parseInt(this.props.totalDevis !==0 ? this.state.situationCumul*100/this.props.totalDevis : 0) + "%"}} class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-500"></div>
                </div>
              </div>
            </div>
      </div>
    )}
}