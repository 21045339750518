import React from 'react';
import Logo from '../../logo_quaero.jpg'
import TrebuchetFont from '../../trebuc.ttf'
import TrebuchetFontBold from '../../trebucbd.ttf'
import { Document, Page, Text,Image, View, PDFViewer, StyleSheet, Font, } from '@react-pdf/renderer';
import { Table, TableHeader, DataTableCell, TableCell, TableBody } from '@david.kucsai/react-pdf-table'
import {} from 'react-pdf';

const styles = StyleSheet.create({
    logo:{
        position: 'absolute',
        height: '70px',
        top: 5,
        left: 30,

    },
    client:{
        height: '80x',
        position:'absolute',
        top: 10,
        right: 50,
        border: '1px',
        padding: '10px',


    },
    title:{
        fontFamily: 'Trebuc',
        fontSize: 12,
        textAlign: 'center',
        margin: 5,
    },
    bold:{
        fontFamily: 'TrebucBold',

        fontSize: 10,
    },
    normal:{
        fontFamily: 'Trebuc',
        fontSize: 10,
    },
    marche:{
        position: 'absolute',
        width: '50%',
        height: '30px',
        top: 150,
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    date:{
        position: 'absolute',
        width: '50%',
        height: 30,
        top: 150,
        right: 0,
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    avenants:{
        top: 140,
        paddingHorizontal: 5,
        width: '50%',
    },
    cell:{
        padding: '1px',
        textAlign: 'center'
    },
    cellImportant:{
        backgroundColor: '#f0f0f0',
        padding: '1px',
        textAlign: 'center'
    },
    footerBar:{
        border:1,
        position: 'absolute',
        marginLeft: 10,
        marginRight: 10,
        width: 575
    },
    paiments:{
        top: 15,
        width: '50%',
        paddingHorizontal: 5,

    },
    situation:{
        position: 'absolute',
        paddingHorizontal: 5,
        top: 15,
        right: 0,
        width: '50%',
    }
  });


  Font.register({
    family: 'Trebuc',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: TrebuchetFont,
  });

  Font.register({
    family: 'TrebucBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: TrebuchetFontBold,
  });

const Pdf = () => <Document>
    <Page size="A4">
    <View>
        <Image
            style={styles.logo}
            src={Logo}/>
    </View>
    <View style={[styles.client]}>
        <Text style={[styles.bold, {marginBottom: '10px', fontSize: 14}]}>M. Jean-François COMPTE</Text>
        <Text style={styles.normal}>21 rue verneuil</Text>
        <Text style={styles.normal}>75007 PARIS</Text>
    </View>
    <View style={[{top:80, width:'50%', paddingHorizontal:5}]}>
        <View style={[{border:1, height:60, paddingHorizontal:10}]}>
            <Text style={[styles.bold, {marginBottom: 6, fontSize: 14, marginTop:5}]}>Architete: ACG architecte</Text>
            <Text style={[styles.normal]}>85 rue Montmartre</Text>
            <Text style={[styles.normal]}>75002 Paris</Text>
        </View>
    </View>
    {/* Chantier et lot */}
    <View style={[styles.date, {top:100}]}>
        <Table data={[{chantier: "Renovation du 21 rue de verneuil paris 7e", lot: "5. Electricité"}]} >
            <TableHeader>
                <TableCell weighting={0.7} style={[styles.normal, styles.cell]}>Chantier</TableCell>
                <TableCell weighting={0.3} style={[styles.normal, styles.cell]}>Lot</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell weighting={0.70} style={[styles.normal, styles.cell]} getContent={(r) => r.chantier}/>
                <DataTableCell weighting={0.30} style={[styles.normal, styles.cell]} getContent={(r) => r.lot}/>
            </TableBody>
        </Table>
    </View>
    <View style={styles.marche}>
        <Table data={[{HT: "100 000 €", TTC: "120 000 €"}]} >
            <TableHeader>
                <TableCell style={[styles.normal, styles.cell]} >Marché (€)</TableCell>
                <TableCell style={[styles.normal, styles.cell]} >HT</TableCell>
                <TableCell style={[styles.normal, styles.cell]} >TTC</TableCell>
            </TableHeader>
            <TableBody>
                <TableCell style={[styles.normal, styles.cell]} >TVA 10%</TableCell>
                <DataTableCell style={[styles.normal, styles.cell, {textAlign: 'right'}]}  getContent={(r) => r.HT}/>
                <DataTableCell style={[styles.normal, styles.cell, {textAlign: 'right'}]}  getContent={(r) => r.TTC}/>
            </TableBody>
        </Table>
    </View>
    <View style={styles.date}>
        <Table data={[{number: "6", date: "Octobre 2021"}]} >
            <TableHeader>
                <TableCell style={[styles.normal, styles.cell]}>Proposition de paiement n° </TableCell>
                <TableCell style={[styles.normal, styles.cell]}>Pour le mois de</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={[styles.normal, styles.cell]} getContent={(r) => r.number}/>
                <DataTableCell style={[styles.normal, styles.cell]} getContent={(r) => r.date}/>
            </TableBody>
        </Table>
    </View>
    <View style={styles.avenants}>
    <Text style={[styles.title]}>Avenants</Text>
    <Table data={[{name: "TEst", HT: "100 000 €", TTC: "120 000 €"},{name: "TEst2", HT: "100 000 €", TTC: "120 000 €"},{name: "TEst3", HT: "100 000 €", TTC: "120 000 €"}]} >
            <TableHeader>
                {/* <TableCell style={[styles.bold, styles.cell]}>Avenants</TableCell> */}
                <TableCell weighting={0.5}  style={[styles.bold, styles.cell, ]}>Nom</TableCell>
                <TableCell weighting={0.25} style={[styles.bold, styles.cell]}>Montant HT</TableCell>
                <TableCell weighting={0.25} style={[styles.bold, styles.cell]}>Montant TTC</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell weighting={0.5} style={[styles.normal, styles.cell]} getContent={(r) => r.name} />
                <DataTableCell weighting={0.25} style={[styles.normal, styles.cell, {textAlign:'right'}]} getContent={(r) => r.HT} />
                <DataTableCell weighting={0.25} style={[styles.normal, styles.cell, {textAlign:'right'}]} getContent={(r) => r.TTC} />
            </TableBody>
        </Table>
        <Table data={[{name: "Total Avenants", totalHT: "100 000 €", totalTTC: "120 000 €"}, {name: "Marchés + avenants", totalHT:"100 000 €", totalTTC:"120 000 €"}]}>
            <TableBody>
                <DataTableCell weighting={0.5} style={[styles.bold, styles.cellImportant, {textAlign:'left', textWeight:'bold'}]} getContent={(r) => r.name} />
                <DataTableCell weighting={0.25} style={[styles.normal, styles.cellImportant, {textAlign:'right'}]} getContent={(r) => r.totalHT} />
                <DataTableCell weighting={0.25} style={[styles.normal, styles.cellImportant, {textAlign:'right'}]} getContent={(r) => r.totalTTC} />
            </TableBody>
        </Table>
    </View>
    <View style={[{position: 'absolute', top: 200, right:0, width: '50%', paddingHorizontal: 5}]}>
        <Text style={[styles.title]}>Entreprise</Text>
        <View style={[{border:1,padding: 5, width:180,alignSelf: 'center'}]}>
            <Text style={[styles.bold, {marginBottom: 3}]}>GED - Agence de gaillon</Text>
            <Text style={[styles.normal]}>CS 80523</Text>
            <Text style={[styles.normal]}>78712 Mantes-la-jolie cedex</Text>
        </View>
        <Text style={[styles.title, ]}>Avancement</Text>
        <View>
            <Table >
                <TableHeader>
                    <TableCell style={[styles.bold, styles.cell,]}>Nom</TableCell>
                    <TableCell style={[styles.bold, styles.cell,]}>Montant</TableCell>
                </TableHeader>
                <TableBody includeTopBorder={1} data={[ {name: "Avancement 1", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 2", amount: "122 000 €"},
                                                        {name: "Avancement 3", amount: "122 000 €"},]}>
                    <DataTableCell style={[styles.normal, styles.cell,]} getContent={(r) => r.name}/>
                    <DataTableCell style={[styles.normal, styles.cell, {textAlign: "right"}]} getContent={(r) => r.amount}/>
                </TableBody>
            </Table>
            <Table>
                <TableBody data={[{cell1: "Avancement Global", cell2: "89 %"}]}>
                    <DataTableCell style={[styles.bold, styles.cellImportant,]} getContent={(r) => r.cell1}/>
                    <DataTableCell style={[styles.bold, styles.cellImportant]} getContent={(r) => r.cell2}/>
                </TableBody>
                {/* <TableBody>
                    <TableCell style={[styles.bold,]}>Avancement global</TableCell>
                    <TableCell style={[styles.bold,]}>87 %</TableCell>
                </TableBody> */}
            </Table>
        </View>
        <Text style={[styles.title]}>À déduire</Text>
        <View style={[{borderTop:1}]}>
            <Table >
                {/* <TableHeader>

                </TableHeader> */}
                <TableBody data={[  {name: "Retenues de garanties", amount: "100 000 €"},
                                    {name: "Cmpte prorata", amount: "100 000 €"},
                                    {name: "Retenues diverses", amount: "100 000 €"},
                                    {name: "Pénalités", amount: "100 000 €"}
                                    ]}
                                    >
                    <DataTableCell style={[styles.normal, styles.cell,]} getContent={(r) => r.name}/>
                    <DataTableCell style={[styles.normal, styles.cell, {textAlign: "right", marginLeft: 10}]} getContent={(r) => r.amount}/>
                </TableBody>
            </Table>
        </View>
        <View style={[{marginTop:20, borderTop: 1}]}>
            <Table>
                <TableBody data={[  {name: "Situation cumulée", amount: "130 000 €"},
                                    {name: "Situation précedente", amount: "110 000 €"},
                                    ]}>
                    <DataTableCell style={[styles.normal, styles.cell, {textAlign: "right", marginLeft: 10}]} getContent={(r) => r.name}/>
                    <DataTableCell style={[styles.normal, styles.cell, {textAlign: "right", marginLeft: 10}]} getContent={(r) => r.amount}/>

                </TableBody>
            </Table>
        </View>
    </View>
    {/* Footer */}
    <View style={[{postion:'absolute', top:450}]}>
        <View style={[styles.footerBar]}></View>
        <View style={[styles.footerBar, {top: 3}]}></View>
        {/* paiments */}
        <View style={[styles.paiments]}>
            <Table data={[  {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"},
                            {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"},
                            {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"},
                            {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"},
                            {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"},
                            {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"},
                            {name: "12/11/2034", HT: "100 000 €", TTC: "120 000 €"}]} >
                <TableHeader>
                    {/* <TableCell style={[styles.bold, styles.cell]}>Avenants</TableCell> */}
                    <TableCell weighting={0.5}  style={[styles.bold, styles.cell, ]}>Paiements effectués</TableCell>
                    <TableCell weighting={0.25} style={[styles.bold, styles.cell]}>Montant HT</TableCell>
                    <TableCell weighting={0.25} style={[styles.bold, styles.cell]}>Montant TTC</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell weighting={0.5} style={[styles.normal, styles.cell]} getContent={(r) => r.name} />
                    <DataTableCell weighting={0.25} style={[styles.normal, styles.cell, {textAlign:'right'}]} getContent={(r) => r.HT} />
                    <DataTableCell weighting={0.25} style={[styles.normal, styles.cell, {textAlign:'right'}]} getContent={(r) => r.TTC} />
                </TableBody>
            </Table>
            <Table>
                <TableBody data={[  {name: "TOTAL Paiements", HT: "130 000 €", TTC: "140 000 €"},
                                    ]}>
                    <DataTableCell weighting={0.5} style={[styles.bold, styles.cellImportant]} getContent={(r) => r.name} />
                    <DataTableCell weighting={0.25} style={[styles.bold, styles.cellImportant, {textAlign:'right'}]} getContent={(r) => r.HT} />
                    <DataTableCell weighting={0.25} style={[styles.bold, styles.cellImportant, {textAlign:'right'}]} getContent={(r) => r.TTC} />
                </TableBody>
            </Table>
        </View>
        {/* Situation à payer */}
        <View style={[styles.situation]}>
            <View  style={[{borderTop:1}]}>
                <TableBody data={[
                    {name: "Situation à payer HT", amount: "16 000 €"},
                    {name: "TVA 10 %", amount: "1 600 €"},
                    {name: "Situation à payer TTC", amount: "17 600 €"},
                ]}>
                    <DataTableCell style={[styles.bold, styles.cellImportant, {textAlign: "right", }]} getContent={(r) => r.name}/>
                    <DataTableCell style={[styles.bold, styles.cellImportant, {textAlign: "right"}]} getContent={(r) => r.amount}/>
                </TableBody>
            </View>
        </View>
        {/* Signature */}
        <View style={[{position:'absolute', width:'50%', right:0, top:70, paddingHorizontal:5}]}>
            <View style={[{border:1,width:'50%', height:15, borderRight:0}]}><Text style={[styles.bold, {textAlign:'center'}]}>Jean-Baptiste Lefebvre</Text></View>
            <View style={[{border:1,width:'50%', height:15, position:'absolute', right:5}]}><Text style={[styles.bold, {textAlign:'center'}]}>Aymerick Chastenet</Text></View>
            <View style={[{border:1,width:'50%', height:100, borderTop:0, borderRight:0}]}></View>
            <View style={[{border:1,width:'50%', height:100, borderTop:0, position:'absolute', right:5, top:15}]}></View>
            <View><Text style={[styles.normal, {marginTop:10, textAlign:'right'}]}>Fait à Triel-sur-Seine le 13/11/2020</Text></View>
        </View>
    </View>
    </Page>
</Document>

export default function CertificatPaiement(){
    return(
        <div>
            <PDFViewer className="h-screen mx-52" style={{width: 900}}>
                <Pdf />
            </PDFViewer>
        </div>
    )
}