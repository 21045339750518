import React, { Component} from 'react';
import { Disclosure} from '@headlessui/react'
import * as heroIcons from 'react-icons/hi';
import { Link} from 'react-router-dom';
import {instance, checkToken} from '../../../globalData';

export class Imputations extends Component {

  constructor(props){
    super(props);
    this.state = {
      imputations: [],
      total: 0,
      html: []
    }
  }

  async getImputations(id){
    var imputLot = []
    await checkToken()
    await instance.get(`detailimputationslot/${id}/`).then((res) => {
      imputLot = res.data
      this.setState({imputations: imputLot})
    })
  }

  async componentWillReceiveProps(props){
    if(this.props.project !== props.project || this.props.selectedLot.id !== props.selectedLot.id){
      await this.getImputations(props.selectedLot.id)
    }
  }

  async componentDidMount(){
    await this.getImputations(this.props.selectedLot.id)
  }

  async onChange(e){
    let prev = this.state.imputations
    prev[e.target.id].active = !prev[e.target.id].active
    this.setState({imputations: prev})
    await checkToken()
    instance.patch(`imputationslot/${prev[e.target.id].id}/`, {"active": prev[e.target.id].active})
  }

  showImputations() {
    if(this.state.imputations.length === 0){
      return(<>Il n'y a aucune imputation pour ce lot</>)
    }
    let content = []
    let totalImputations = 0
    this.state.imputations.map((imput, index) => {
      if(imput.pourcent !== 0){
        content.push(
          <tr>
            <td className="border border-black text-center">{imput.imputation.name}</td>
            <td className="border border-black text-right">{imput.pourcent*imput.imputation.amount/100}</td>
            <td className="border border-black text-center"><input id={index} onChange={(e) => this.onChange(e)} type="checkbox" checked={imput.active ? 1: 0} className="checked:bg-blue-600 checked:border-transparent"></input></td>
          </tr>
        )
        if(imput.active){
          totalImputations += imput.pourcent*imput.imputation.amount/100
        }
      }
    })
    return(
      <>
      <table className="table-fixed">
      <thead>
        <tr>
          <th className="w-1/2">Motif</th>
          <th className="w-1/2">Montant HT</th>
          <th className="w-1/3">Appliquer</th>
        </tr>
      </thead>
      <tbody className="">
        {content}
      </tbody>
      </table>
      <h2 className="font-bold mt-2">Total Emputations HT:  {totalImputations}</h2>
      </>
    )
  }
    render(){
    return(
      <div className="w-full">
        <div className="w-full max-w-md p-2 mx-auto bg-gray-300 rounded-2xl">
            <h2 className="font-bold ">Imputations</h2>
                <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span>Imputations</span>
                      <heroIcons.HiOutlineChevronDown
                        className={`${
                          open ? "transform rotate-180" : ""
                        } w-5 h-5 text-white`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-coolGray-500">
                    {this.showImputations()}
                      <Link className="w-56 px-3 py-1 flex m-4 mx-auto text-base items-center bg-gray-400 hover:bg-gray-500 rounded-full"
                            to={`/imputations/${this.props.project}/`}
                            role="button"
                      >
                        <heroIcons.HiOutlineArrowCircleRight className="pr-1 text-xl"/>Afficher les imputations
                      </Link>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
      </div>
    )
  }
};